

















































































import { mapState, mapActions, mapMutations, mapGetters } from "vuex";
import Vue from "vue";

import store from "@/store";

export default Vue.extend({
  name: "Settings",
  components: {},
  data: () => ({
    darkTheme: false,
    showWGS: true,
    debugStatus: false,
    animateWaterStatus: false,
    fps: {
      text: "Traget frame rate ",
      target: 45,
      min: 1,
      max: 60,
      show: false,
    },
    naturalEarthResolutions: {
      worldSelection: -1,
      world: [
        { title: "Minmum quality with tiles.", wtValue: 0 },
        { title: "Low - 1350p, 0.61 MB", wtValue: 1 },
        { title: "Medium - 2700p, 2.4 MB", wtValue: 2 },
        { title: "High - 5400p, 9.2 MB", wtValue: 3 },
        { title: "Ultra - 10800p, 32.0 MB", wtValue: 4 },
      ],
      spectralSelection: -1,
      spectral: [
        { title: "Low - 1350p, 0.21 MB", wtValue: 0 },
        { title: "Medium - 2700p, 0.55 MB", wtValue: 1 },
        { title: "High - 5400p, 1.4 MB", wtValue: 2 },
        { title: "Ultra - 10800p, 4.2 MB", wtValue: 3 },
      ],
    },
    ...mapGetters([]),
  }),
  computed: {
    ...mapState({
      storeNaturalEarthResolutions: (state: any) =>
        state.settings.gfx.naturalEarthResolutions,
    }),
  },
  watch: {
    updateFpsShow(newValue: boolean) {
      this.$store.commit("mSettingsGfxSetFpsShow", newValue);
    },
    animateWaterStatus(newValue: boolean) {
      this.$store.commit("mSettingsGfxSetAnimateWater", newValue);
    },
    darkTheme(newValue: boolean) {
      this.$store.commit("mSettingsUISetDarkTheme", newValue);
      this.$vuetify.theme.dark = (this.$store
        .state as typeof store.state).settings.UI.darkTheme;
    },
    debugStatus(newValue: boolean) {
      this.$store.commit("mSettingsSetDebugStatus", newValue);
    },
    showWGS(newValue: boolean) {
      this.$store.commit("mSettingsSetShowWGS", newValue);
    },
  },
  methods: {
    ...mapActions([]),
    ...mapMutations([]),
    naturalEarthResHelper(wtValue: number) {
      this.$store.commit("mSettingsCesiumNaturalEarthResolutionWorld", wtValue);
    },
    naturalEarthResSpectralHelper(wtValue: number) {
      this.$store.commit(
        "mSettingsCesiumNaturalEarthResolutionSpectral",
        wtValue
      );
    },

    updateFpsTarget() {
      //console.warn("slider update triggerd with: " + this.fps.target);
      this.$store.commit("mSettingsGfxSetFpsTarget", this.fps.target);
    },
  },
  created: function() {
    this.naturalEarthResolutions.worldSelection = this.storeNaturalEarthResolutions.world;
    this.naturalEarthResolutions.spectralSelection = this.storeNaturalEarthResolutions.spectral;
    this.debugStatus = (this.$store
      .state as typeof store.state).settings.debug.isActive;
    this.animateWaterStatus = (this.$store
      .state as typeof store.state).settings.gfx.animateWater;
    this.fps.target = (this.$store
      .state as typeof store.state).settings.gfx.fpsTarget;
    this.fps.show = (this.$store
      .state as typeof store.state).settings.gfx.fpsShow;
    this.darkTheme = (this.$store
      .state as typeof store.state).settings.UI.darkTheme;
    this.showWGS = (this.$store
      .state as typeof store.state).settings.UI.showWGS;
  },
});
